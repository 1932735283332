/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import QSModal from '../../molecules/modal';
import { QSButton, QSTypography } from '../../atoms';
import useNotifications from '../../../hooks/use-notifications';
import { useSetProfile } from '../../../hooks/use-profile';
import { logoutUseCase } from '../../../../modules/login/v1/use-cases/logout-use-case';
import { trackLogout } from '../../../../infra/analytics/utils';
import { LoginStepAtom } from '../../../../modules/login/v1/state';
import { useSetAtom } from 'jotai';
import { LoginStep } from '../../../states/login';
import Loader from '../../molecules/loader';
import { firebaseAuth } from '../../../../infra/firebase/init';

interface LogoutModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  cancelText?: string;
  confirmText?: string;
}

const LogoutModal: React.FC<LogoutModalProps> = ({
  open,
  onClose,
  title = 'Are you sure you want to logout?',
  cancelText = 'CANCEL',
  confirmText = 'LOGOUT',
}) => {
  const { addNotification } = useNotifications();
  const setUserProfile = useSetProfile();
  const setLoginStep = useSetAtom(LoginStepAtom);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const handleLogout = async () => {
    try {
      setIsLogoutLoading(true);
      await logoutUseCase();
    } catch (error) {
      addNotification({
        type: 'error',
        message: (error as Error).message,
      });
      return;
    } finally {
      setIsLogoutLoading(false);
    }
    await firebaseAuth.signOut();
    trackLogout();
    setLoginStep(LoginStep.PHONE);
    setUserProfile(undefined);
  };
  return (
    <QSModal open={open} onClose={onClose}>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 50%;
          padding: 32px;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          border-radius: 5px;
          &:focus-visible {
            outline: none;
            border: none;
          }
        `}
      >
        <QSTypography
          css={css`
            font-size: 18px;
            margin-bottom: 24px;
          `}
        >
          {title}
        </QSTypography>
        <Box
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
          `}
        >
          <QSButton
            css={css`
              margin-right: 16px;
              padding: 4px 12px;
              font-size: 16px;
              background: none;
              :hover {
                background: #efefef90;
              }
            `}
            onClick={onClose}
            variant="text"
          >
            {cancelText}
          </QSButton>
          <QSButton
            css={css`
              padding: 4px 16px;
              font-size: 16px;
              color: white;
              :hover {
                background: rgba(63, 144, 109, 0.9);
              }
            `}
            onClick={handleLogout}
          >
            {isLogoutLoading ? (
              <Box
                css={css`
                  width: 68px;
                  height: 28px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <Loader
                  size={16}
                  loaderProps={{
                    sx: {
                      color: '#fff',
                    },
                  }}
                />
              </Box>
            ) : (
              confirmText
            )}
          </QSButton>
        </Box>
      </Box>
    </QSModal>
  );
};

export default LogoutModal;
